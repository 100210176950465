body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.background {
  background-image: url('../Images/service.jpg'); /* Replace with the correct path to your background image */
  background-size: cover;
  background-position: center;
  height: 100vh; /* Default height for large screens */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: backgroundFadeIn 2s ease-in-out;
}

.centered-text {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 80px;
  line-height: 1.3;
  opacity: 0;
  transform: scale(0.8);
  animation: textFadeScale 1.5s ease-out forwards, textGlow 3s infinite alternate;
}

/* Fade-in and scale effect for text */
@keyframes textFadeScale {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Subtle glow effect for the text */
@keyframes textGlow {
  0% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.4), 0 0 20px rgba(255, 255, 255, 0.3);
  }
  100% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.6), 0 0 40px rgba(255, 255, 255, 0.5);
  }
}

/* Background fade-in effect */
@keyframes backgroundFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Slide-in effect for text */
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Rotating effect for text */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Bouncing effect for text */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* Apply different animations */
.centered-text.slide-in {
  animation: slideIn 1.5s ease-out forwards, textGlow 3s infinite alternate;
}

.centered-text.rotate {
  animation: rotate 2s linear infinite, textGlow 3s infinite alternate;
}

.centered-text.bounce {
  animation: bounce 2s infinite, textGlow 3s infinite alternate;
}

/* Responsive Styles */

/* Medium Screens (Tablets) */
@media (max-width: 1024px) {
  .background {
    height: 80vh; /* Reduce height for tablet screens */
  }

  .centered-text {
    font-size: 60px; /* Adjust font size for tablet */
  }
}

/* Small Screens (Phones) */
@media (max-width: 768px) {
  .background {
    height: 70vh; /* Further reduce height for phones */
  }

  .centered-text {
    font-size: 40px; /* Reduce font size for phones */
  }
}

/* Extra Small Screens (Very Small Devices) */
@media (max-width: 480px) {
  .background {
    height: 60vh; /* Even smaller height for very small devices */
  }

  .centered-text {
    font-size: 30px; /* Smallest font size for very small devices */
  }
}