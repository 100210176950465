/* General Styles */
body {
  background-color: #004f39;
  font-family: Arial, sans-serif;
  color: #004b32;
  margin: 0;
  padding: 0;
}

.page2-container {
  background-color: #004f39;
  color: white;
  text-align: left;
  padding: 20px 40px;
  font-family: 'Roboto', sans-serif;
}

.page2-container h2 {
  font-size: 25px;
  font-weight: normal;
  margin: 0 auto 40px;
  line-height: 1.4;
  max-width: 1500px;
  text-align: left;
}

/* Grid Container */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px; /* Reduced gap */
  max-width: 1200px; /* Reduced width */
  margin: 20px auto;
  padding: 0 10px;
}

/* Project Box */
.project-box {
  position: relative;
  border-radius: 8px; /* Reduced border radius */
  overflow: hidden;
  background: white;
  padding: 10px; /* Reduced padding */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.project-box img {
  width: 100%;
  height: 250px; /* Reduced height */
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
}

.project-box:hover img {
  filter: grayscale(0%);
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

/* Modal Content */
.modal-content {
  background: #004b32;
  padding: 15px; /* Reduced padding */
  border-radius: 8px; /* Reduced border radius */
  width: 600px; /* Reduced width */
  display: flex;
  flex-direction: column;
  color: #004b32;
  position: relative;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
}

/* Popup Layout */
.popup-layout {
  display: flex;
  gap: 15px;
  align-items: center;
}

/* Popup Sidebar */
.popup-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px; /* Reduced width */
  gap: 8px;
}

.popup-box {
  background: #ffffff;
  padding: 8px; /* Reduced padding */
  border-radius: 5px;
  text-align: center;
  flex: 1;
  min-height: 70px; /* Reduced height */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

/* Ensure the logo fits properly */
.popup-box img {
  max-width: 100%;
  height: auto;
  max-height: 80px;
}

/* Popup Main */
.popup-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Ensure both sections (image & text) have equal height */
.popup-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
}

/* Popup Image */
.popup-image {
  height: 180px; /* Reduced height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Popup Description */
.popup-description {
  background: #f4f4f4;
  padding: 8px;
  border-radius: 5px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Reduced font size */
  font-weight: 500;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px; /* Adjusted spacing */
  }
  .project-box img {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .project-box img {
    height: 180px;
  }
  .modal-content {
    width: 85%;
  }
  .popup-layout {
    flex-direction: column;
    align-items: center;
  }
  .popup-sidebar {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .popup-main {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
  .project-box img {
    height: 160px;
  }
}
