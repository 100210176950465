/* Base Styles */
.join-body-custom {
    background: linear-gradient(135deg, #e0f2f1, #c8e6c9); /* Smooth gradient background */
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 50px 20px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Container */
.join-container-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    text-align: center;
    background: #ffffff;
    padding: 50px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    gap: 30px;
}

/* Text Section */
.text-section-custom {
    flex: 1;
    text-align: left;
    padding: 0 20px;
}

.text-section-custom h1 {
    font-size: 50px;
    font-weight: 700;
    color: #004d00;
    margin-bottom: 20px;
    text-transform: uppercase;
    position: relative;
}

/* Subheading with Highlight */
.text-section-custom p {
    font-size: 25px;
    color: #333;
    font-weight: 500;
    margin-bottom: 20px;
}

.highlight-text {
    color: #00796b;
    font-weight: 700;
}

/* Email Section */
.text-section-custom1 p {
    font-size: 20px;
    color: #004d00;
    font-weight: 600;
}

.email-link {
    font-size: 22px;
    font-weight: bold;
    color: #00796b;
    text-decoration: none;
    display: inline-block;
    background: #e0f7fa;
    padding: 12px 25px;
    border-radius: 30px;
    margin-top: 15px;
    transition: all 0.3s ease-in-out;
}

.email-link:hover {
    background: #00796b;
    color: white;
}

/* Image Section */
.image-section-custom {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

/* RESPONSIVE DESIGN */

/* Tablets */
@media screen and (max-width: 1024px) {
    .text-section-custom h1 {
        font-size: 45px;
    }

    .text-section-custom p {
        font-size: 22px;
    }

    .email-link {
        font-size: 18px;
        padding: 10px 20px;
    }

    .join-container-custom {
        flex-direction: column;
        text-align: center;
        padding: 40px;
    }

    .text-section-custom {
        text-align: center;
    }
}

/* Mobile */
@media screen and (max-width: 600px) {
    .text-section-custom h1 {
        font-size: 35px;
    }

    .text-section-custom p {
        font-size: 18px;
    }

    .highlight-text {
        font-size: 20px;
    }

    .email-link {
        font-size: 16px;
        padding: 8px 15px;
    }

    .team-image {
        max-width: 80%;
    }

    .join-container-custom {
        padding: 30px;
    }
}
