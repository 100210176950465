/* Base Styles */
.services-container {
  padding: 50px 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.services-title {
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 40px;
  color: #333333;
}

/* Horizontal Scroll Grid for Cursor Movable */
.services-grid {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  gap: 20px;
  padding: 20px;
  scroll-snap-type: x mandatory; /* Snap scrolling */
  scrollbar-width: thin; /* Firefox scrollbar styling */
}

.service-item {
  flex: 0 0 auto;
  width: 300px; /* Card width */
  scroll-snap-align: start; /* Snap to start on scroll */
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.service-item:hover {
  transform: translateY(-10px);
}

.service-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  object-fit: cover;
}

.service-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color:black;
}

.service-description {
  font-size: 18px;
  color: #555;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .services-title {
    font-size: 30px;
  }

  .service-item {
    width: 250px; /* Adjust card width for smaller screens */
  }
}

@media (max-width: 480px) {
  .services-title {
    font-size: 24px;
  }

  .service-description {
    font-size: 16px;
  }

  .service-heading {
    font-size: 20px;
  }
}
