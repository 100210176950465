/* Base Page Styles */
.collab-page {
  text-align: center;
  background-color: #ffffff; /* Clean white background */
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  color: #004A36; /* Dark green color for text */
}

/* Headings */
.collab-page h1,
.collab-page h2 {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 3rem; /* Increased size for both headings */
  line-height: 1.2; /* Improved readability */
}

.collab-page h2 {
  margin-top: 50px; /* Maintain spacing for the second heading */
}

/* Logos Section */
.collab-logos {
  display: grid;
  gap: 30px;
  justify-content: center;
  margin: 0 auto;
}

/* 4 Columns for Employee Section */
.employees-section {
  grid-template-columns: repeat(4, 1fr);
}

/* 3 Columns for Software Section */
.software-section {
  grid-template-columns: repeat(3, 1fr);
}

/* Individual Logo Styling */
.collab-logo img {
  width: 160px; /* Increased width for larger logos */
  height: 160px; /* Increased height for larger logos */
  object-fit: contain; /* Keeps aspect ratio */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* More prominent shadow */
  transition: transform 0.2s ease-in-out;
}

/* Hover Effect */
.collab-logo img:hover {
  transform: scale(1.1); /* Slightly larger zoom effect */
}

/* Responsive Design */

/* For Tablets */
@media (max-width: 1024px) {
  .employees-section {
      grid-template-columns: repeat(3, 1fr); /* Adjust to 3 columns */
  }
  .software-section {
      grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns */
  }
  .collab-logo img {
      width: 140px; /* Slightly smaller for tablets */
      height: 140px;
  }
}

/* For Mobile Devices */
@media (max-width: 768px) {
  /* Reduce the heading font size for mobile */
  .collab-page h1,
  .collab-page h2 {
      font-size: 5.5vw; /* Decreased heading size */
  }

  /* Two logos per row for both sections */
  .employees-section,
  .software-section {
      grid-template-columns: repeat(2, 1fr); /* Two logos per row */
  }
  
  .collab-logo img {
      width: 120px; /* Smaller size for mobile */
      height: 120px;
  }
}

/* For Small Mobile Devices */
@media (max-width: 480px) {
  /* Two logos per row for both sections */
  .employees-section,
  .software-section {
      grid-template-columns: repeat(2, 1fr); /* Two logos per row */
  }
  
  .collab-logo img {
      width: 100px; /* Smallest size for very small screens */
      height: 100px;
  }
}