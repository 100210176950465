/* Footer Styles */
.footer {
  position: relative;
  padding: 50px;
  color: white;
  display: flex;
  justify-content: space-between; /* Original text and box on opposite ends */
  align-items: center;
  min-height: 90vh; /* Adjusted for smaller screens */
  background-image: url('../Images/footer.jpg'); /* Ensure path is correct */
  background-size: cover; /* Cover ensures the image covers the area without stretching */
  background-position: center center; /* Center the background image */
  background-attachment: fixed; /* Optional: this will make the background image fixed on scroll */
  overflow: hidden; /* Prevent overflow issues */
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  z-index: 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap; /* Enable wrapping for smaller screens */
  position: relative;
  z-index: 1;
  padding: 20px;
}

.footer-text h1 {
  font-size: clamp(2rem, 5vw, 4.5rem); /* Responsive font scaling */
  font-weight: bold;
  color: #FFFFFF;
  line-height: 1.3;
  margin: 0 20px; /* Adjust spacing */
}

.footer-contact {
  background-color: #838A9B;
  padding: 30px;
  border-radius: 8px; /* Added a slight border-radius for aesthetics */
  text-align: left;
  height: auto;
  width: 40%; /* Default width */
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  margin: 20px 0;
}

.footer-contact p {
  font-size: clamp(1rem, 2vw, 1.5rem); /* Responsive scaling */
  margin: 10px 0;
}

.footer-contact a {
  color: white;
  text-decoration: none;
  font-size: clamp(1rem, 2vw, 1.5rem); /* Responsive scaling */
}

.footer-contact a:hover {
  text-decoration: underline;
}

/* Media Queries */
@media (max-width: 1024px) {
  .footer {
    flex-direction: column; /* Stack content vertically for tablets */
    text-align: center;
    min-height: 60vh; /* Adjust height */
    background-size: cover; /* Ensure full background image without stretching */
  }

  .footer-content {
    flex-direction: column; /* Stack content */
    align-items: center;
  }

  .footer-text h1 {
    margin: 0 0 20px 0; /* Reduce spacing for smaller screens */
    text-align: center;
  }

  .footer-contact {
    width: 80%; /* Expand contact box */
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .footer-contact {
    padding: 20px;
    width: 90%; /* Take almost full width on smaller screens */
  }

  .footer-text h1 {
    font-size: 2rem; /* Reduce heading size for smaller screens */
  }
}

@media (max-width: 480px) {
  .footer {
    min-height: 50vh; /* Further reduce height for mobile */
    background-size: cover; /* Ensure background image covers the space */
  }

  .footer-text h1 {
    font-size: 35px; /* Further reduce heading size */
    line-height: 1.2;
  }

  .footer-contact {
    padding: 15px;
    width: 100%; /* Full width for mobile */
    text-align: center; /* Center align text */
  }

  .footer-contact p, 
  .footer-contact a {
    font-size: 1rem; /* Adjust font size */
  }
}
