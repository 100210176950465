/* HomePagePro.css */

/* Base Styles */
.homepage-pro-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

/* Hero Section */
.homepage-pro-hero {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-pro-hero-img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: brightness(40%); /* Dim the image for overlay text contrast */
}

/* Dark Gradient Overlay */
.homepage-pro-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3));
}

/* Content Section */
.homepage-pro-content {
  position: absolute;
  text-align: center;
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Logo Container */
.homepage-pro-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.homepage-pro-logo {
  width: 200px;
  height: 200px;
}

.homepage-pro-company-name {
  font-size:70px;
  font-weight: bold;
  letter-spacing: 2px;
}

/* Typing Animation */
.homepage-pro-text h1 {
  font-size: 70px;
  font-weight: bold;
  line-height: 1.3;
  text-transform: uppercase;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .homepage-pro-content {
      width: 90%;
  }

  .homepage-pro-logo-container {
      flex-direction: column;
      gap: 5px;
  }

  .homepage-pro-company-name {
      font-size: 1.8rem;
  }

  .homepage-pro-text h1 {
      font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .homepage-pro-text h1 {
      font-size: 2rem;
  }

  .homepage-pro-logo {
      width: 200px;
  }
}
