/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Base Styles */
.member-page {
    text-align: left; /* Align content to the left */
    background-color: #f5f5f5; /* Light gray background */
    padding: 40px; /* Padding around the container */
    font-family: 'Roboto', sans-serif;
    color: #004A36; /* Dark green text color */
}

.member-page h1 {
    font-size: 2.5vw; /* Use viewport width for scaling */
    margin-bottom: 20px; /* Space below heading */
    line-height: 1.4; /* Line height for readability */
    font-weight: 700; /* Bold heading */
    color: #004A36;
    text-align: center; /* Center-align heading */
}

/* Grid Layout */
.member-logos {
    display: grid; /* Use grid layout for larger screens */
    grid-template-columns: repeat(3, 1fr); /* Always 3 columns */
    gap: 20px; /* Space between grid items */
    margin-top: 20px;
    justify-items: center; /* Center align items in their grid cells */
}

/* Individual Logo Styling */
.member-logo img {
    max-width: 80%; /* Set max size for images */
    height: auto; /* Maintain aspect ratio */
    border-radius: 0; /* No rounded corners for natural shape */
    background-color: transparent; /* Ensure no background */
    transition: transform 0.2s ease-in-out; /* Smooth hover effect */
}

.member-logo img:hover {
    transform: scale(1.05); /* Slight zoom on hover */
}

/* Responsive Design */

/* For Tablets and Laptop Devices (max-width: 1024px) */
@media (max-width: 1024px) {
    .member-page h1 {
        font-size: 2vw; /* Adjust font size */
    }

    .member-logos {
        grid-template-columns: repeat(3, 1fr); /* Maintain 3 columns */
    }

    .member-logo img {
        max-width: 75%; /* Slightly smaller images */
    }
}

/* For Mobile Devices (max-width: 768px) */
@media (max-width: 768px) {
    .member-page h1 {
        font-size: 3vw; /* Adjust font size for smaller screens */
    }

    .member-logos {
        display: flex; /* Use flexbox for horizontal scrolling */
        overflow-x: auto; /* Enable horizontal scrolling */
        gap: 15px; /* Add space between images */
        padding: 10px 20px; /* Add padding to the container */
        scroll-snap-type: x mandatory; /* Snap images when scrolling */
        align-items: center; /* Vertically center images */
    }

    .member-logo {
        flex: 0 0 auto; /* Prevent images from shrinking */
        scroll-snap-align: center; /* Ensure images snap to the center */
        display: flex;
        justify-content: center; /* Center images horizontally */
        align-items: center; /* Center images vertically */
    }

    .member-logo img {
        max-width: 100%; /* Adjust image size to fit container */
        height: auto;
    }
}

/* For Extra Small Devices (max-width: 480px) */
@media (max-width: 480px) {
    .member-page h1 {
        font-size: 5.5vw; /* Smaller text */
    }

    .member-logos {
        gap: 10px; /* Smaller gaps */
        padding: 10px 15px; /* Adjust padding for smaller screens */
    }

    .member-logo img {
        max-width: 80%; /* Further scaling down for smaller devices */
        height: auto;
    }
}