/* Main Container */
.model-container {
    text-align: center;
    background-color: #1b3b7a; 
    color: white;
    padding: 60px 20px; /* Reduced padding */
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers everything vertically */
}

/* Title & Subtitle */
.model-title {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 15px;
}

.model-subtitle {
    font-size: 20px;
    max-width: 1000px;
    margin: 0 auto;
    line-height: 1.6;
}

/* 🌟 Default - Semicircle View (Desktop) */
.model-items-container {
    display: flex;
    justify-content: center;
    margin-top: 40px; /* Reduced from 80px */
}

.model-semicircle {
    position: relative;
    width: 650px;
    height: 350px;
    border-top-left-radius: 350px;
    border-top-right-radius: 350px;
    border: 6px solid #3fa9f5;
    border-bottom: none;
    margin-bottom: 0; /* Remove bottom space */
}

/* Items Positioning */
.model-item {
    position: absolute;
    background-color: #3fa9f5;
    color: white;
    font-weight: bold;
    padding: 16px 22px;
    border-radius: 30px;
    font-size: 14px;
    white-space: nowrap;
    text-align: center;
}

/* Positions */
.top { top: -8%; left: 50%; transform: translateX(-50%); }
.left { left: -12%; top: 42%; }
.bottom-left { left: -10%; bottom: -10%; }
.right { right: -12%; top: 42%; }
.bottom-right { right: -10%; bottom: -10%; }

/* 🌟 Mobile View (≤600px) */
@media (max-width: 600px) {
    .model-title {
        font-size: 32px;
    }

    .model-subtitle {
        font-size: 18px;
        padding: 0 15px;
    }

    .model-items-container {
        margin-top: 20px; /* Reduce space in mobile */
    }

    .model-semicircle {
        width: 85%;
        max-width: 320px;
        height: 180px;
        border-top-left-radius: 180px;
        border-top-right-radius: 180px;
        border: 4px solid #3fa9f5;
        margin-bottom: 0; /* Remove bottom space */
    }

    .model-item {
        padding: 10px 15px;
        font-size: 12px;
    }

    /* Adjusting Positions */
    .top { top: -12%; left: 50%; transform: translateX(-50%); }
    .left { left: -8%; top: 45%; }
    .bottom-left { left: -6%; bottom: -10%; }
    .right { right: -8%; top: 45%; }
    .bottom-right { right: -6%; bottom: -10%; }
}
