/* Apply styles only when the body has the class 'contact1-body' */
.contact1-body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.contact1-background {
  background-image: url('../Images/contact.jpg'); /* Ensure this path is correct */
  background-size: cover;
  background-position: center;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

/* Dark overlay for better text visibility */
.contact1-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.contact1-centered-text {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 6vw; /* Use relative units for responsiveness */
  line-height: 1.3;
  white-space: nowrap;
  z-index: 2;
  position: relative;
}

/* Animate only the question mark */
.falling-question {
  display: inline-block;
  animation: fallBounce 2s ease-out forwards;
  opacity: 1; /* Always visible */
}

/* Fade-in animation for "REACH OUT" */
.fade-in-text {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 2s ease-in forwards;
  animation-delay: 2s; /* Starts after the question mark animation */
}

/* Keyframe for falling question mark with bounce effect */
@keyframes fallBounce {
  0% {
    transform: translateY(-200px); /* Start above */
    opacity: 1; /* Always visible */
  }
  50% {
    transform: translateY(20px); /* Overshoot for bounce effect */
  }
  100% {
    transform: translateY(0); /* Settle at final position */
  }
}

/* Keyframe for fade-in text */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .contact1-centered-text {
    font-size: 8vw; /* Adjust font size for smaller screens */
    padding: 0 20px; /* Add padding to prevent text cutoff */
  }
}

@media (max-width: 480px) {
  .contact1-centered-text {
    font-size: 10vw; /* Increase font size for very small screens */
    white-space: normal; /* Allow text to wrap */
    line-height: 1.5; /* Improve readability */
  }
}

@media (max-width: 400px) {
  .contact1-centered-text {
    font-size: 12vw; /* Further increase font size for very small screens */
    padding: 0 10px; /* Reduce padding to fit content better */
  }

  .falling-question {
    animation: fallBounce 1.5s ease-out forwards; /* Slightly faster animation */
  }

  .fade-in-text {
    animation-delay: 1.5s; /* Adjust delay to match faster animation */
  }
}