body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.contact2-container {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  background-color: #004f39;
  color: white;
  padding: 5%;
  box-sizing: border-box;
  flex-direction: row; /* Default: left and right sections side by side */
}

.contact2-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.contact2-left h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.contact2-left p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 0 auto;
}

.contact2-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin: 0 auto; /* Ensure the form is centered */
  align-items: center; /* This will center the input fields horizontally */
}

label {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: white;
  text-align: left; /* Keep the label left-aligned */
  width: 100%;
}

input,
textarea {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 1.2rem;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

textarea {
  resize: none;
  height: 150px;
}

input::placeholder,
textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

strong {
  font-weight: bold;
}

/* Responsive styles for mobile versions */
@media (max-width: 768px) {
  .contact2-container {
    flex-direction: column; /* Stack left and right sections */
    padding: 10%;
  }

  .contact2-left {
    margin-bottom: 30px;
  }

  .contact2-left h1 {
    font-size: 2.5rem; /* Reduce header size */
  }

  .contact2-left p {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 auto;
  }

  .contact2-right {
    flex: none;
    margin-top: 20px; /* Add space between the left and right sections */
  }

  form {
    padding: 0 10px; /* Add padding inside the form */
  }

  input,
  textarea {
    padding: 12px; /* Adjust padding for smaller screens */
    font-size: 1rem;
  }

  label {
    font-size: 1.3rem;
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) {
  .contact2-container {
    flex-direction: column; /* Stack the left and right sections vertically */
    padding: 0 10%; /* Ensure padding is consistent on smaller screens */
  }

  .contact2-left {
    width: 100%; /* Ensure the left section takes up full width */
    padding: 15px;
  }

  .contact2-right {
    width: 100%; /* Ensure the right section takes up full width */
    display: block; /* Force block display for right section */
    padding: 15px;
    margin-top: 20px; /* Space between sections */
    text-align: center; /* Center the content in the right section */
  }

  .contact2-left h1 {
    font-size: 2rem; /* Reduce header size for small screens */
  }

  .contact2-left p {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  label {
    font-size: 1.2rem;
    width: 100%; /* Make sure labels are full-width */
  }

  input,
  textarea {
    padding: 12px;
    font-size: 1rem;
    width: 80%; /* Center the text boxes by giving them a width of 80% */
    margin-left: auto;
    margin-right: auto; /* Center the inputs horizontally */
  }

  form {
    padding: 0 10px; /* Add padding inside the form */
    margin: 0 auto; /* Ensure form is centered horizontally */
  }
}

/* New media query for screens less than 350px */
@media (max-width: 350px) {
  .contact2-container {
    flex-direction: column; /* Stack the left and right sections vertically */
    padding: 0 5%; /* Ensure padding is consistent */
  }

  .contact2-left {
    width: 100%; /* Ensure the left section takes up full width */
    padding: 10px;
  }

  .contact2-right {
    width: 100%; /* Ensure the right section takes up full width */
    display: block; /* Force block display for right section */
    padding: 10px;
    margin-top: 20px; /* Space between sections */
    text-align: center; /* Center the content in the right section */
  }

  .contact2-left h1 {
    font-size: 1.8rem; /* Reduce header size for very small screens */
  }

  .contact2-left p {
    font-size: 0.8rem;
    line-height: 1.5;
  }

  label {
    font-size: 1rem; /* Adjust label font size */
    width: 100%;
  }

  input,
  textarea {
    padding: 10px; /* Slightly reduce padding for very small screens */
    font-size: 0.9rem; /* Adjust font size for better readability */
    width: 85%; /* Ensure inputs take up less space and stay centered */
    margin-left: auto;
    margin-right: auto; /* Center the inputs horizontally */
  }

  form {
    padding: 0 10px; /* Add padding inside the form */
    margin: 0 auto; /* Ensure form is centered horizontally */
  }
}
