/* Navbar Container */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Navbar Left (Logo + Brand) */
.navbar-left {
  display: flex;
  align-items: center;
  gap: 5px;
}

.navbar-logo {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
}

.navbar-brand {
  font-size: 22px;
  font-weight: bold;
  background: linear-gradient(90deg, #131415, #091d1f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-top: 0;
}

.navbar-brand:hover {
  transform: scale(1.1);
  text-shadow: 0 0 10px rgba(0, 188, 212, 0.7);
}

/* Navbar Links */
.navbar-links {
  display: flex;
  gap: 20px;
  list-style: none;
}

/* Links - Bright Color for Better Visibility */
.navbar-links a {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  transition: color 0.3s ease;
}

/* Underline on Hover */
.navbar-links a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: #0037ff; /* Blue underline */
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.navbar-links a:hover::after {
  transform: scaleX(1);
}

/* Mail Us Button */
.navbar-button {
  padding: 10px 20px;
  background: linear-gradient(90deg, #007bff, #00bcd4);
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.navbar-button:hover {
  background: linear-gradient(90deg, #00bcd4, #007bff);
  transform: scale(1.1);
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  z-index: 1100;
}

/* Hamburger Bars */
.hamburger span {
  width: 30px;
  height: 4px;
  background-color: rgb(0, 55, 128);
  border-radius: 3px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

/* Hamburger Open State */
.hamburger.open span:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

/* Mobile View */
@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .navbar-links.active {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: fixed;
    top: 60px; /* Positioned below navbar */
    left: 0; /* Opens from left */
    background: rgba(99, 98, 98, 0.9);
    padding: 15px 20px;
    width: 60%; /* Menu covers 60% of the screen */
    height: auto; /* Adjusts height dynamically */
    max-height: 300px; /* Prevents it from being too tall */
    overflow-y: auto; /* Enables scrolling if needed */
    z-index: 1001;
    text-align: left;
    border-radius: 0 8px 8px 0;
    transition: transform 0.3s ease;
  }

  /* Brighter Menu Items */
  .navbar-links.active a {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
  }

  /* Show Hamburger in Mobile */
  .hamburger {
    display: flex;
  }
}
