/* Base Styles */
.page6-container {
    text-align: center;
    background-color: #e8ebf2; /* Light greyish-blue */
    padding: 100px 20px 50px; /* Increased top padding */
    position: relative;
    overflow: hidden;
}

/* Title */
.page6-title {
    font-size: 50px;
    font-weight: bold;
    color: #004b32; /* Dark Green */
    margin-bottom: 40px;
    position: relative;
    z-index: 2; /* Ensure title is above background */
    top: -50px;
}

/* Button Grid */
.page6-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px; /* More spacing between buttons */
    justify-content: center;
    max-width: 900px;
    margin: 0 auto;
    position: relative;
    z-index: 2; /* Ensure buttons are above background */
}

/* Expertise Buttons */
.page6-btn {
    background-color: #004b32; /* Dark Green */
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 25px 30px; /* Default padding */
    border-radius: 40px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
}

.page6-btn:hover {
    background-color: #00301f; /* Darker Green */
}

/* Background Shapes (Under Buttons) */
.background-shape {
    position: absolute;
    width: 400px;
    height: 300px;
    background: rgba(0, 75, 50, 0.2); /* Dark green tint */
    border-radius: 50%;
    z-index: 1; /* Below buttons */
}

.background-shape.left {
    top: 40%;
    left: 5%;
}

.background-shape.middle {
    top: 60%;
    left: 40%;
    background: rgba(0, 100, 255, 0.2); /* Light blue */
}

.background-shape.right {
    top: 30%;
    right: 10%;
    background: rgba(0, 75, 50, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
    .page6-title {
        font-size: 35px;
    }
    .page6-buttons {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 500px) {
    .page6-title {
        font-size: 30px;
    }
    .page6-buttons {
        grid-template-columns: repeat(1, 1fr);
        gap: 15px; /* Less spacing between buttons */
    }
    .page6-btn {
        font-size: 14px; /* Smaller text */
        padding: 15px 20px; /* Reduced padding */
        border-radius: 30px; /* Adjusted for smaller buttons */
    }
}
