/* Global Styles */
body {
  background-color: #004d40; /* Dark green background */
  font-family: Arial, sans-serif;
  color: white;
  margin: 0;
  padding: 0;
}

.company-profile-page {
  position: relative;
  text-align: center;
  color: white;
}

.background-image {
  position: relative;
  width: 100%;
  height: 60vh; /* Default height for larger screens */
  background-image: url('../Images/2.jpg'); /* Ensure the path is correct */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 69, 57, 0.4); /* Blue overlay with transparency */
}


.content {
  position: relative;
  z-index: 1;
  padding: 20px;
}

.company-profile-page h1 {
  font-size: clamp(2.5rem, 5vw, 4.5rem); /* Responsive scaling for headings */
  line-height: 1.3;
  margin: 0;
  font-weight: bold; /* Bold text */
  color: white; 
}

.company-profile-page p {
  font-size: clamp(1.5rem, 3vw, 2rem); /* Responsive scaling for text */
  line-height: 1.6;
  margin: 20px 0;
  color: white; /* Ensure the text is white */
}

.company-profile-page .read-more-link {
  color: white; 
  text-decoration: underline;
  font-size: clamp(1.2rem, 2.5vw, 1.8rem); /* Responsive scaling for link */
  line-height: 1;
  cursor: pointer;
  transition: color 0.3s;
}

.company-profile-page .read-more-link:hover {
  color: #00e676; /* Change color on hover for interactivity */
}

/* Media Queries */
@media (max-width: 1024px) {
  .background-image {
    height: 50vh; /* Reduce height for medium screens */
  }
}

@media (max-width: 768px) {
  .background-image {
    height: 40vh; /* Reduce height further for smaller screens */
  }

  .company-profile-page h1 {
    font-size: 2.5rem; /* Scale down heading size */
  }

  .company-profile-page p {
    font-size: 1.5rem; /* Scale down paragraph size */
  }

  .company-profile-page .read-more-link {
    font-size: 1.2rem; /* Scale down link size */
  }
}

@media (max-width: 480px) {
  .background-image {
    height: 35vh; /* Adjust height for mobile */
  }

  .content {
    padding: 10px; /* Reduce padding for mobile */
  }

  .company-profile-page h1 {
    font-size: 2rem; /* Further scale down heading */
  }

  .company-profile-page p {
    font-size: 1.2rem; /* Further scale down paragraph */
  }

  .company-profile-page .read-more-link {
    font-size: 1rem; /* Further scale down link */
  }
}
