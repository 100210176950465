/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Base Styles */
.why-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eaf4f5; /* Light blue-gray background */
  padding: 40px;
  font-family: 'Roboto', sans-serif;
  color: #004a36; /* Dark green text color */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  gap: 20px; /* Add spacing between left and right sections */
}

.why-left {
  flex: 1;
  padding-left: 40px; /* Shift text slightly to the right */
  padding-right: 20px;
}

.why-left h1 {
  font-size: 3rem; /* Scaled heading size */
  margin-bottom: 20px;
  line-height: 1.4;
  font-weight: 700;
  text-transform: uppercase; /* Matches the image heading style */
}

.why-left p {
  font-size: 1.25rem; /* Adjusted font size */
  line-height: 1.8; /* Improved readability with more spacing */
}

.why-right {
  flex: 1;
  display: flex;
  justify-content: center;
}

.why-right img {
  max-width: 65%; /* Adjusted width for consistency */
  height: auto;
  border-radius: 20px; /* Added curved border to the image */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for the image */
}

/* Media Queries */

/* For Tablets and Laptop Devices (max-width: 1024px) */
@media (max-width: 1024px) {
  .why-page {
    flex-direction: column;
    padding: 20px;
  }

  .why-left, .why-right {
    padding: 0;
    text-align: center; /* Center text for smaller screens */
  }

  .why-left h1 {
    font-size: 2.5rem; /* Adjusted heading size for tablets */
  }

  .why-left p {
    font-size: 1.1rem; /* Adjusted paragraph size for tablets */
  }

  .why-right img {
    max-width: 80%; /* Increase image size for tablets */
  }
}

/* For Mobile Devices (max-width: 768px) */
@media (max-width: 768px) {
  .why-left h1 {
    font-size: 2rem;
  }

  .why-left p {
    font-size: 1rem;
  }

  .why-right img {
    max-width: 90%; /* Further increase image size */
  }
}

/* For Extra Small Devices (max-width: 480px) */
@media (max-width: 480px) {
  .why-left h1 {
    font-size: 1.5rem;
  }

  .why-left p {
    font-size: 0.9rem;
  }

  .why-right img {
    max-width: 100%; /* Full-width image for small devices */
  }
}
