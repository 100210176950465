.who-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #004d40;
    height: 100vh; /* Increased height */
    padding: 0 5vw;
    flex-wrap: nowrap;
}

.who-text h1 {
    color: white;
    font-size: 5.5vw;
    font-weight: bold;
    line-height: 1.3;
    text-align: center;
    margin: 0;
}

.logo-section {
    display: flex;
    justify-content: flex-start; /* Move logo to the left */
    align-items: center;
    width: 60%; /* Take more space for the logo */
    min-height: 50vh; /* Ensure it takes enough height */
}

.logo-image {
    width: 350px;  /* Explicit width */
    height: 350px; /* Explicit height */
}

/* Responsive Design */
/* For Tablets */
@media (max-width: 1024px) {
    .who-container {
        flex-direction: column;
        padding: 5vw;
        height: auto;
    }

    .who-text h1 {
        font-size: 5vw;
    }

    .logo-section {
        justify-content: center; /* Center logo on smaller screens */
        width: 100%;
    }

    .logo-image {
        max-width: 150%;
        max-height: 150%;
    }
}

/* For Mobile Devices */
@media (max-width: 768px) {
    .who-text h1 {
        font-size: 4.5vw;
    }

    .logo-image {
        max-width: 100%;
        max-height: 100%;
    }
}

/* For Extra Small Devices */
@media (max-width: 480px) {
    .who-text h1 {
        font-size: 6vw;
        line-height: 1.2;
    }

    .logo-image {
        max-width: 85%;
        max-height: 85%;
    }
}