.page7-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #003b2e; /* Dark green */
    color: white;
    padding: 80px;
    min-height: 70vh;
}

/* Left section */
.page7-left {
    flex: 1;
}

.page7-title {
    font-size: 55px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 10px; /* Reduced margin to bring button closer */
}

.page7-button {
    background-color: #00aaff;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 24px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    margin-top: 10px; /* Reduced margin to move button closer */
}

/* Right section */
.page7-right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    flex: 1.2;
    justify-content: center;
}

.page7-box {
    background: transparent;
    border: 2px solid white;
    border-radius: 15px;
    padding: 30px;
    text-align: center;
    min-width: 220px;
}

.page7-icon {
    height: 120px;
    width: 140px;
    margin-bottom: 10px;
}

.page7-box p {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

/* 🔹 Mobile View - Stack boxes in a single column */
@media (max-width: 768px) {
    .page7-container {
        flex-direction: column;
        padding: 40px;
        text-align: center;
    }

    .page7-left {
        width: 100%;
        text-align: center;
    }

    .page7-title {
        font-size: 35px;
    }

    .page7-button {
        font-size: 16px;
        padding: 10px 20px;
    }

    .page7-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        width: 100%;
        margin-top: 30px;
    }

    .page7-box {
        width: 80%;
        min-width: auto;
        padding: 25px;
    }

    .page7-icon {
        height: 100px;
        width: 120px;
    }

    .page7-box p {
        font-size: 16px;
    }
}
