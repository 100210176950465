/* Main Container */
.sol2-container {
  background-color: #003b2e;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Grid Container for First Row */
.sol2-grid-container {
  display: flex;
  justify-content: space-between;
  width: 900px;
  gap: 50px;
  margin-bottom: 20px;
}

/* Project Box (Image Wrapper) */
.sol2-project-box {
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  position: relative;
  width: 100%;
}

/* Image Styling */
.sol2-project-box img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

/* Text Above Image */
.sol2-text-top {
  text-align: center;
  color: white;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Text Below Image */
.sol2-text-bottom {
  text-align: center;
  color: white;
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
}

/* Wide Image (Second Row) */
.sol2-wide {
  max-width: 900px;
  width: 100%;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .sol2-grid-container {
    flex-direction: column;
    width: 100%;
  }

  .sol2-text-top, .sol2-text-bottom {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .sol2-project-box img {
    height: 200px;
  }

  .sol2-text-top, .sol2-text-bottom {
    font-size: 18px;
  }
}
