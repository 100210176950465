/* ProjectsPage.css */
body {
  background-color: #004d40; /* Dark green background */
  font-family: Arial, sans-serif;
  color: white;
  margin: 0;
  padding: 0;
}

.projects-page {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  padding: 20px; /* Increase padding */
  align-items: center;
  justify-content: space-between;
  gap: 20px; /* Add spacing between sections */
  min-height: 100vh; /* Ensure the background color covers the full viewport height */
}

/* Left Section (Image Grid) */
.left-section {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 20px; /* Spacing between grid items */
  padding: 10px;
}

.image-container img,
.image-container1 img {
  width: 100%;
  height: 30vh;
  border-radius: 10px;
}

/* Right Section (Text Content) */
.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center content */
  padding: 20px;
  text-align: center;
}

.right-section h1 {
  font-size: clamp(2rem, 5vw, 4rem); /* Responsive font scaling */
  line-height: 1.3;
  margin-bottom: 20px;
  font-weight: bold;
  color: #ffffff;
}

.right-section p {
  font-size: clamp(1rem, 2vw, 1.5rem);
  line-height: 1.6;
  margin-bottom: 20px;
  color: #ffffff;
  max-width: 80%;
}

.read-more-link {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  color: #ffffff;
  text-decoration: underline;
}

.read-more-link:hover {
  color: #e0e0e0;
}

/* Button Styling */
.button-container {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.explore-btn,
.contact-btn {
  padding: 12px 24px;
  font-size: 1.2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.explore-btn {
  background-color: #2196f3; /* Orange */
  color: white;
}

.explore-btn:hover {
  background-color: #e68900;
}

.contact-btn {
  background-color: #2196f3; /* Blue */
  color: white;
}

.contact-btn:hover {
  background-color: #e68900;
}

/* Media Queries */

/* For Tablets */
@media (max-width: 1024px) {
  .projects-page {
    flex-direction: column;
    gap: 30px;
  }

  .left-section {
    grid-template-columns: 1fr;
  }

  .right-section {
    text-align: center;
    align-items: center;
    padding: 20px;
  }
}

/* For Mobile Devices */
@media (max-width: 768px) {
  .projects-page {
    flex-direction: column;
    gap: 20px;
  }

  .right-section {
    order: -1;
    text-align: center;
    align-items: center;
    padding: 10px;
  }

  .left-section {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .right-section h1 {
    font-size: 35px;
    margin: 10px 0;
  }

  .right-section p {
    font-size: 1rem;
    margin: 15px 0;
  }

  .read-more-link {
    font-size: 0.9rem;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
  }

  .explore-btn,
  .contact-btn {
    width: 100%;
    font-size: 1rem;
  }
}

/* For Extra Small Devices */
@media (max-width: 480px) {
  .projects-page {
    gap: 10px;
  }

  .left-section {
    grid-gap: 10px;
  }

  .right-section h1 {
    font-size: 1.8rem;
    margin: 10px 0;
  }

  .right-section p {
    font-size: 1.2rem;
    margin: 10px 0;
  }

  .read-more-link {
    font-size: 1.2rem;
  }
}
