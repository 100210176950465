/* General Styles */
body.page1-body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.page1-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Default height for large screens */
  background-image: url('../Images/3.jpg'); /* Ensure path is correct */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevents overflow issues with large images */
}

.page1-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(50, 50, 50, 0.9), rgba(0, 0, 0, 0.6));
  animation: gradientMove 6s infinite alternate ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page1-container h1 {
  color: #FFFFFF;
  font-size: 7rem; /* Default size for large screens */
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  opacity: 0;
  animation: textReveal 1.5s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  line-height: 1.2; /* Ensures line spacing for multi-line text */
}

/* Text reveal sliding effect */
@keyframes textReveal {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Gradient background animation */
@keyframes gradientMove {
  0% {
    background: linear-gradient(135deg, rgba(50, 50, 50, 0.9), rgba(0, 0, 0, 0.6));
  }
  100% {
    background: linear-gradient(225deg, rgba(0, 0, 0, 0.6), rgba(50, 50, 50, 0.9));
  }
}

/* Media Queries */

/* Large Screens (Desktop) */
@media (min-width: 1025px) {
  .page1-container {
    height: 100vh; /* Full height for large screens */
  }

  .page1-container h1 {
    font-size: 7rem; /* Large font for large screens */
  }
}

/* Tablet (max-width: 1024px) */
@media (max-width: 1024px) {
  .page1-container {
    height: 70vh; /* Reduce container height for tablets */
    background-size: cover; /* Keep background cover */
  }

  .page1-container h1 {
    font-size: 5rem; /* Adjust heading size for tablets */
  }
}

/* Mobile (max-width: 768px) */
@media (max-width: 768px) {
  .page1-container {
    height: 50vh; /* Reduce container height for mobile devices */
    background-size: cover; /* Ensure image is covered */
  }

  .page1-container h1 {
    font-size: 4rem; /* Adjust heading size for mobile */
  }
}

/* Small Mobile Devices (max-width: 480px) */
@media (max-width: 480px) {
  .page1-container {
    height: 40vh; /* Further reduce container height for very small screens */
    background-size: cover; /* Keep background cover */
  }

  .page1-container h1 {
    font-size: 2.5rem; /* Further reduce heading size for very small screens */
  }
}
